body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Wallpaper{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 100%;
  }



.textPhishing{
  color: black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;

}

.center{
    text-align: right;
    display: flex;
    flex: 1;
    align-items: right;
    margin-left: auto;
    width: 30%;
  }

.centerHeaderImage{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }




